.s-container{
    position: relative;
}

.s-container .swiper{
    width: 70%;
    height: 12rem;
    position: unset;
}

.s-container .swiper-slide{
    background: white;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev{
    left: 6rem;
}

.s-container .swiper-button-next{
    right: 6rem;
}

.s-container .swiper-button-prev, .swiper-button-next, .s-container{
    color: black;
}

.left-s{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name{
    display: flex;
    flex-direction: column;
}

.name>:first-child{
    font-size: 1.5rem;
    font-weight: 600;
}

.name>:nth-child(2){
    font-weight: bold;
    font-style: 2rem;


}
.name>:nth-child(3){
    font-size: 2rem;
    border: 0.1px solid var(
        --black
    );
    padding:5px 8px;
    width:max-content;
    border-radius: 15px;
    font-weight: 500;
}

.img-p{
    transform: rotate(-20deg);
    position: absolute;
    right: 0;
    width: 40%;
    height: 100%;
    bottom: -20%;
}